<template>
	<div :class="themeClass" class="min-width1100 max-width1100 margin-lr-auto" style="width: 1110px;margin-top: 20px;background-color: #FFFFFF;">
		<!-- 面包屑 -->
		<!-- <div class="breadcrumb">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item to="/">首页</el-breadcrumb-item>
				<el-breadcrumb-item to="/about/my">个人中心</el-breadcrumb-item>
				<el-breadcrumb-item>购物车</el-breadcrumb-item>
			</el-breadcrumb>
		</div> -->
		<breadcrumb></breadcrumb>
		<!-- 面包屑END -->
		<div class="" v-if="allProducts != null">
			<!-- <p class="color-theme font-size16 font-weight700 margin-b-10 margin-t-6">全部商品({{allProducts}})</p> -->
			<div class="flex-row-space-between-center font-size14" style="height: 35px;background: #F2F4FD;">
				<div class="flex-row-align-center">
					<el-checkbox v-model="checkedAll" @change="All"></el-checkbox>
					<div class="flex-row-align-center">
						<div class="">全选</div>
					</div>
				</div>
				<div class="">商品信息</div>
				<div class="flex-row-space-between-center">
					<p class="width156" style="text-align: center;">数量</p>
					<p class="width156" style="text-align: center;">单价</p>
				</div>
			</div>
			<div class="wrap">
				<div class="page-box ">
					<!-- <el-checkbox-group> -->
					<div v-for="(item, index) in Data" :key="index" class="">
						<div class="flex-row-align-center" style="height: 35px;background: #F2F4FD;">
							<el-checkbox v-model="item.checked" @change="shopCheckedChoose($event, index)"></el-checkbox>
							<i class="iconfont icon-dianpu4 color-theme"></i>
							<div class="font-size14">店铺：{{ item.fShopName }}</div>
						</div>
						<div v-for="(subItem, subIndex) in item.shoppingCar" :key="subIndex" class="flex-row-space-between-center shopCart pointer"
						 style="">
							<div class="flex-row-start-start" style="height: 65px;">
								<div class="flex-row-space-between-center">
									<el-checkbox :label="subItem.fGoodsFullName" v-model="subItem.checked" @change="goodsCheckedChoose($event, index, subIndex)"></el-checkbox>
									<div class="box-shadow-all imageRadius" style="" @click="toGoodsDetails(subItem)">
										<el-image class="imageRadius" :src="subItem.fPicturePath"></el-image>
									</div>
								</div>
								<div style="width: 650px;height: 65px;" class="padding-lr-10" @click="toGoodsDetails(subItem)">
									<div class="font-color-000">{{ subItem.fGoodsFullName }}</div>
									<div class="flex-row" style="margin-top: 8px;">
										<!-- <div class="tag-box-red  font-size12 ">限时优惠</div> -->
										<div class="tag-box-blue color-theme border-theme font-size12">{{ subItem.fDelivery }}</div>
									</div>
								</div>
							</div>
							<div class="flex-row-align-center" style="height: 65px;">
								<div class="flex-column-align-center width156" style="border-left: 1px solid #F2F2F2;height: 65px;">
									<el-input-number size="small" v-model="subItem.fAmount" :min="subItem.fMinSaleAmount" :max="99999" :precision="subItem.fPrecision"
									 :step="subItem.fAdjustmentRange" @change="valChange($event, index, subIndex)" @focus="getInputFocus($event)"></el-input-number>
								</div>
								<div class="flex-column-align-center font-color-FF0000 width156" style="border-left: 1px solid #F2F2F2;height: 65px;"
								 @click="toGoodsDetails(subItem)">
								 <div class="flex-row">
									 <p class="font-weight700">￥<span v-if='subItem.fGoodsMinPrice == subItem.fGoodsPrice ? false:true'>{{ subItem.fGoodsMinPrice }}~</span><span>{{ subItem.fGoodsPrice }}</span></p>
									 <p v-if="subItem.fUnitName" class="font-weight700 margin-l-3">元/{{ subItem.fUnitName }}</p>
								 </div>
									<p v-if='subItem.fGoodsMinPrice == subItem.fGoodsPrice ? true:false'   class="font-size11 font-color-FF9900" style="">距加入降价￥{{ subItem.fGoodsDiscount }}</p>
								</div>
							</div>
						</div>
					</div>
					<div class="pagination flex-row-end-center">
						<pagination :currentPage="currentPage" :pagesize="pagesize" :total="total" @handleCurrentChange="handleCurrentChange"></pagination>
					</div>
				</div>
				<div class="flex-row-space-between-center bottomBox" style="">
					<div class="flex-row-align-center font-size14 font-color-666" style="width: 40%;">
						<el-checkbox v-model="checkedAll" @change="All"></el-checkbox>
						<div class="flex-row-space-between-center">
							<div class="pointer">全选</div>
							<div class="margin-l-17 pointer" @click="deleteOrder">删除选中商品</div>
							<!-- <div class="margin-l-17 pointer" @click="collection">移入收藏</div> -->
						</div>
					</div>
					<div class="flex-row-space-between-center font-size14 font-color-666">
						<div class="flex-column-start-end" style="margin-right: 30px;">
							<div class="flex-row-align-center">
								已选择
								<p class="font-color-FF0000 font-weight700 font-size14">{{ totalLength }}</p>
								件商品,总价：
								<p class="font-color-FF0000 font-weight700 font-size18">¥{{ money | NumFormat }}</p>
							</div>
							<!-- <div class="">优惠券：- ¥150.00</div> -->
						</div>
						<button style="width: 120px;height: 40px;margin-right: 20px;" class="background-color-theme pointer" @click="gotoOrder">去结算</button>
					</div>
				</div>
			</div>
		</div>
		<div class="flex-row-center-center" v-else>
			<svg-icon icon-class="ShoppingCartEmpty" />
		</div>
		<!-- <router-view></router-view> -->
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	import breadcrumb from '@/components/breadcrumb.vue';
	import pagination from "@/components/pagination/pagination";
	export default {
		components: {
			breadcrumb,
			pagination
		},
		data() {
			return {
				allProducts: null, //总共有多少商品
				numberbox: 0,
				checkList: [],
				/*  */
				money: 0,
				totalLength: 0,
				checkedAll: false,
				Data: [],
				// 当前页数
				currentPage: 1,
				// 每页条数
				pagesize: 5,
				// 总条数
				total: 0,
				acsData:{},
			};
		},
		mounted() {
			this.getDataList();
		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		methods: {
			toGoodsDetails(obj) {
				console.log('obj', obj);
				this.$router.push({
					name: "GoodsDetails",
					query: {
						fGoodsID: obj.fGoodsID,
					},
				});
			},
			// 当前页 改变时会触发
			handleCurrentChange(Current) {
				this.currentPage = Current;
				this.checkedAll = false;
				this.getDataList();
			},
			collection() {
				this.$message({
					type: 'success',
					message: '加入收藏!'
				});
			},
			deleteOrder() {
				var checkedGoods = [];
				for (var i = 0; i < this.Data.length; i++) {
					for (var ii = 0; ii < this.Data[i].shoppingCar.length; ii++) {
						if (this.Data[i].shoppingCar[ii].checked == true) {
							let tempData = {
								fShoppingCartID: this.Data[i].shoppingCar[ii].fShoppingCartID,
								frv: this.Data[i].shoppingCar[ii].frv
							};
							checkedGoods.push(tempData);
						}
					}
				}
				if (checkedGoods.length == 0) {
					this.$message({
						type: 'info',
						message: '请选择商品'
					});
					return;
				} else {
					this.$confirm('是否删除这' + checkedGoods.length + '件商品', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						})
						.then(() => {
							this.ApiRequestPost('api/mall/ebsale/shopping-cart/delete-shopping-cart', checkedGoods).then(
								res => {
									this.checkedAll = false;
									this.getDataList();
									this.getCarCuont();
								},
								error => {
									this.$message({
										type: 'success',
										message: 'error.message'
									});
								}
							);
						})
						.catch(() => {});
				}
			},
			//获取购物车数量
			getCarCuont() {
				this.ApiRequestPostNOMess(
					"api/mall/ebsale/shopping-cart/get-shopping-cart-count"
				).then(
					(result) => {
						this.$store.commit("setCarCount", result.obj.number);
					},
					(rej) => {}
				);
			},
			/* 获取总金额和选择的商品数量 */
			moneyTotal() {
				var tempMoney = 0;
				var tempLength = 0;
				for (var i = 0; i < this.Data.length; i++) {
					for (var ii = 0; ii < this.Data[i].shoppingCar.length; ii++) {
						if (this.Data[i].shoppingCar[ii].checked == true) {
							tempMoney = this.Data[i].shoppingCar[ii].fGoodsPrice * this.Data[i].shoppingCar[ii].fAmount + tempMoney;
							tempLength++;
						}
					}
				}
				this.money = tempMoney;
				this.totalLength = tempLength;
			},
			getDataList() {
				this.ApiRequestPost('api/mall/ebsale/shopping-cart/get-shopping-cart-list', {
					maxResultCount: this.pagesize,
					skipCount: (this.currentPage - 1) * this.pagesize
				}).then(
					res => {
						this.allProducts = null;
						for (var i = 0; i < res.obj.items.length; i++) {
							this.allProducts += res.obj.items[i].shoppingCar.length;
							res.obj.items[i].checked = false;
							for (var ii = 0; ii < res.obj.items[i].shoppingCar.length; ii++) {
								res.obj.items[i].shoppingCar[ii].checked = false;
							}
						}
						this.Data = res.obj.items;
						this.total = res.obj.totalCount;
						this.loading = false;
					},
					error => {}
				)
			},
			/* 全选 */
			All(event) {
				for (var i = 0; i < this.Data.length; i++) {
					this.Data[i].checked = event;
					for (var ii = 0; ii < this.Data[i].shoppingCar.length; ii++) {
						this.Data[i].shoppingCar[ii].checked = event;
					}
				}
				this.moneyTotal();
			},
			/* 判断是否全选 */
			isCheckedAll() {
				for (var i = 0; i < this.Data.length; i++) {
					for (var ii = 0; ii < this.Data[i].shoppingCar.length; ii++) {
						if (this.Data[i].shoppingCar[ii].checked == false) {
							this.checkedAll = false;
							return;
						} else {
							this.checkedAll = true;
						}
					}
				}
			},
			shopCheckedChoose(event, index) {
				this.Data[index].checked = event;

				for (let item of this.Data[index].shoppingCar) {
					item.checked = event;
				}
				this.isCheckedAll();
				this.moneyTotal();
			},
			goodsCheckedChoose(event, index, subIndex) {
				// let array = event.name.split(',');
				/*
				数组0 是店铺  下标
				数组1 是商品  下标
				*/
				/* 确定当前多选的状态如果有任何一个商品是false,那么店铺的状态必然是false,如果所有的商品都是true,那么店铺就是true */
				for (let item of this.Data[index].shoppingCar) {
					if (item.checked == false) {
						this.Data[index].checked = false;
						break;
					} else {
						this.Data[index].checked = true;
					}
				}
				this.isCheckedAll();
				this.moneyTotal();
			},
			valChange(val, index, subIndex) {
				// if (this.Data[index].shoppingCar[subIndex].fAmount % this.Data[index].shoppingCar[subIndex].fAdjustmentRange != 0 &&
				// 	this.Data[index].shoppingCar[subIndex].fAdjustmentRange != 0) {
				// 	this.Data[index].shoppingCar[subIndex].fAmount = (this.Data[index].shoppingCar[subIndex].fAmount / this.Data[index]
				// 		.shoppingCar[subIndex].fAdjustmentRange).toFixed(0) * this.Data[index].shoppingCar[subIndex].fAdjustmentRange;
				// }
				// this.Data[index].shoppingCar[subIndex].fAmount = val;
				if (!val) {
					this.Data[index].shoppingCar[subIndex].fAmount = 0
					return
				}
				var updateGoodsCount = {
					fShoppingCartID: this.Data[index].shoppingCar[subIndex].fShoppingCartID,
					fGoodsPrice: this.Data[index].shoppingCar[subIndex].fGoodsPrice,
					fPointPrice: this.Data[index].shoppingCar[subIndex].fPointPrice,
					fAmount: this.Data[index].shoppingCar[subIndex].fAmount,
					frv: this.Data[index].shoppingCar[subIndex].frv
				};
				let _this = this;
				this.ApiRequestPutNOMess('api/mall/ebsale/shopping-cart/update-shopping-cart', updateGoodsCount).then(
					res => {
						this.moneyTotal();
					},
					error => {
						this.$refs.uToast.show({
							title: error.error.message,
							// 如果不传此type参数，默认为default，也可以手动写上 type: 'default'
							type: 'error',
							// 如果不需要图标，请设置为false
							icon: false
						});
					}
				);
			},
			//计数器获得焦点全选内容
			getInputFocus(event) {
				event.currentTarget.select();
			},
			async gotoOrder() {
				// 购物车支付 前要判断是否同一店铺商品 验证之后再验证 购买非自营店商品时，如果用户没有开通 账服通账户
				//判断当前店铺是否有选中商品，如果有选中商品，就把此店铺isCheckedDetail置为true
				for (let item of this.Data) {
					item.isCheckedDetail = false;
					for (let childtem of item.shoppingCar) {
						if (childtem.checked) {
							item.isCheckedDetail = true;
						}
					}
				}
				console.log('this.Data', this.Data);

				var TempDataArray = [];
				let goodsID = []
				// 店铺ID
				let fShopUnitID = null;
				// 是否同一店铺
				let isSameShop = true;
				// 是否是非自营店铺
				let isNonProprietary = false;
				let count = 0; //计算选中了几个店的店铺
				let arr = []; //判断选中商品是不是一个店
				let arr1 = []; //非自营店数据
				this.Data.forEach((item, index) => {
					if (item.isCheckedDetail) {
						count += 1;
						if (count >= 1) {
							console.log('item', item);
							arr.push(item);
							console.log('count', count);
							// if (index == 0) {
							// 	console.log('item.fShopUnitID', item.fShopUnitID);
							// 	// 先记录第一个店铺
							// 	fShopUnitID = item.fShopUnitID;
							// 	// 判断是否非自营 FShopTypeID 1 自营店 2 非自营店
							if (item.fShopTypeID == 2) {
								for (let childtem of item.shoppingCar) {
									arr1.push({
										id: childtem.fGoodsID
									})
								}
								isNonProprietary = true;
							}
							// } else {
							// 	// 其余店铺再一一与记录店铺ID作比对 不一致则中断循环
							// 	if (fShopUnitID != this.Data[index].fShopUnitID) {
							// 		console.log('第一个店铺fShopUnitID', fShopUnitID);
							// 		isSameShop = false;
							// 		// break;
							// 	}
							// }
						}
					}
				});
				console.log('arr', arr);
				for (var i = 0; i < arr.length; i++) {
					for (var j = 0; j < arr.length - i - 1; j++) {
						if (arr[i].fShopUnitID != arr[j + 1].fShopUnitID) {
							isSameShop = false;
						}
					}
				}

				for (let item of this.Data) {
					for (let childtem of item.shoppingCar) {
						if (childtem.checked) {
							TempDataArray.push(childtem);
							goodsID.push({
								id: childtem.fGoodsID
							})
						}
					}
				}
				// 非同一店铺商品需要提示用户
				if (isSameShop == false) {
					this.$message('请选择同一店铺商品进行支付');
					return
				}
				if (TempDataArray.length <= 0) {
					this.$message('请选择商品');
					return;
				}
				
				arr.forEach((item)=>{
					// 判断不是自营店时
					if(item.fShopTypeID !=  1){
						this.ApiRequestPostNOMess('/api/mall/ebbalance/customer-acs/get-my-ACS').then(
						result=>{
							this.acsData =result.obj;
							// 没有开通账服通
							if(this.acsData == null){
								this.$confirm('亲，请先开通帐服通钱包?', '提示', {
								         confirmButtonText: '前往开通',
								         cancelButtonText: '取消',
								         type: 'warning'
								       }).then(() => {
								         this.$router.replace({
								         	path: '/businessme/AccountPay',
								         });
										
								       }).catch(() => {   
								       });
								
							}else{
								this.$store.commit("changeCommonData", TempDataArray);
								this.$router.push({
									name: 'FillOrder',
									// params: {
                  //   radioIdSelects: JSON.stringify(radioIdSelects),
                  // }
								})
							}
						})
						
					}else{  //如果是自营店，
					   this.$store.commit("changeCommonData", TempDataArray);
					   this.$router.push({
					   	name: 'FillOrder',
					   	// params: {
              //   radioIdSelects: JSON.stringify(radioIdSelects),
              // }
					   })
					}
				})
				
				// // 是否非自营
				// if (isNonProprietary == true) {
				// 	// 此接口主要用于判断 当购买非自营店商品时，如果用户没有开通 账服通账户就不可购买
				// 	await this.ApiRequestPostNOMess('api/mall/ebsale/order/get-is-sale-by-goods-id-batch', arr1).then(
				// 		res => {
				// 			// 如果res.obj数组内 isSale 等于false ，那么需要提示用户message内的消息
				// 			res.obj.forEach((item, index) => {
				// 				if (item.isSale == false) {
				// 					this.$message(item.message);
				// 					return;
				// 				}
				// 			});
				// 		},
				// 		error => {})
				// }

				// this.ApiRequestPostNOMess('api/mall/eb-customer/address/get-default', {}).then(
				// 	res => {
				// 		if (res.obj) {
				// 			res.obj.fFullPathAddress = res.obj.fFullPathAddress.replace(/\\/g, '');

				// 			let isSaleParams = [];
				// 			let createOrderInitParams = [];
				// 			TempDataArray.forEach((item, index) => {
				// 				let isSaleData = {
				// 					addressId: res.obj.fAddressID,
				// 					goodsId: item.fGoodsID,
				// 					deliveryId: item.fDeliveryID
				// 				}
				// 				let createOrderData = {
				// 					addressId: res.obj.fAddressID,
				// 					goodsId: item.fGoodsID,
				// 					deliveryId: item.fDeliveryID,
				// 					fCarrierID: 0,
				// 					fUsePoint: 0,
				// 					fPlanAmount: item.fAmount,
				// 					fAppTypeID: this.fAppTypeID,
				// 					fIsNeedInvoiceStatus: 1, //是否需要发票
				// 					fIsNeedFInvoiceStatus: 0 //是否需要运输发票
				// 				}
				// 				isSaleParams.push(isSaleData);
				// 				createOrderInitParams.push(createOrderData);
				// 			})
				// 			//判断商品在默认地址下能不能销售
				// 			this.ApiRequestPostNOMess('api/mall/ebsale/goods-price/get-list-goods-current-price-by-address-id-batch',
				// 					isSaleParams)
				// 				.then(
				// 					res => {
				// 						if (res.obj.length <= 0) {
				// 							this.$confirm('当前地址下商品不支持销售', '提示', {
				// 								confirmButtonText: '确定',
				// 								cancelButtonText: '取消',
				// 								type: 'warning'
				// 							}).then(() => {
				// 								return;
				// 							}).catch(() => {
				// 								return;
				// 							})
				// 						} else {
				// 							let createOrderParams = [];
				// 							res.obj.forEach((item, index) => {
				// 								if (item.fIsEnableSale == 1) {
				// 									this.$store.commit("changeCommonData", TempDataArray);
				// 									this.$router.push({
				// 										name: 'FillOrder',
				// 										params: {}
				// 									})
				// 								} else {
				// 									// fRemark	"亲，此商品暂不对游客销售"
				// 									// this.$confirm('当前地址下商品不支持销售', '提示', {
				// 									this.$confirm(item.fRemark, '提示', {
				// 										confirmButtonText: '确定',
				// 										cancelButtonText: '取消',
				// 										type: 'warning'
				// 									}).then(() => {
				// 										return;
				// 									}).catch(() => {
				// 										return;
				// 									})
				// 								}
				// 							});
				// 						}
				// 					},
				// 					error => {}
				// 				);
				// 		} else {
				// 			this.$confirm('暂无默认地址，请维护默认地址！', '提示', {
				// 				confirmButtonText: '确定',
				// 				cancelButtonText: '取消',
				// 				type: 'warning'
				// 			}).then(() => {
				// 				return;
				// 			}).catch(() => {
				// 				return;
				// 			})
				// 		}
				// 	},
				// 	error => {}
				// );
			}
		}
	};
</script>

<style lang="scss" scoped>
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
			color: themed('text_color_white');
		}
	}

	.border-theme {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.border-bottom-theme {
		@include themify($themes) {
			border-bottom: 1px solid themed('themes_color');
		}
	}

	.pagination {
		width: 100%;
	}

	/* 面包屑CSS */
	// .el-tabs--card .el-tabs__header {
	// 	border-bottom: none;
	// }
	.breadcrumb {
		margin-top: 15px;
		background-color: white;
	}

	// .breadcrumb .el-breadcrumb {
	// 	width: 1225px;
	// 	line-height: 30px;
	// 	font-size: 16px;
	// 	margin: 0 auto;
	// }
	/* 面包屑CSS END */

	::v-deep .el-checkbox {
		padding: 0 10px !important;
	}

	// 购物车
	.wrap {
		width: 100%;
		margin-bottom: 50px;
		margin-top: 9px;
	}

	// 下方按钮
	/* 新的购物车样式 */

	.tag-box-red {
		// margin: 0px !important;
		margin-right: 8px;
		padding: 0 4px;
		height: 15px;
		line-height: 15px;
		color: #ffffff;
		background-image: linear-gradient(to right, #ff0000, #ff9999);
		// border-radius: 4px;
		font-weight: 400;
	}

	.tag-box-blue {
		// margin: 0px !important;
		padding: 0 5px;
		height: 13px;
		line-height: 13px;
		color: #ffffff;
		font-weight: 400;
	}

	.margin-l-17 {
		margin-left: 17px;
	}

	.shopCart {
		margin: 10px 0px;
		// padding: 25px 0px;
		border: 1px solid #f2f2f2;
		height: 116px;
	}

	.imageRadius {
		width: 65px;
		height: 65px;
		border-radius: 10px;
	}

	.width156 {
		width: 156px;
	}

	.bottomBox {
		height: 70px;
		background: #f8f9fe;
		width: 100%;
	}

	::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
	.el-checkbox__input.is-indeterminate .el-checkbox__inner {
		@include themify($themes) {
			background-color: themed('themes_color');
			border-color: themed('themes_color');
		}
	}

	::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
	.el-checkbox__input.is-indeterminate .el-checkbox__inner {
		@include themify($themes) {
			background-color: themed('themes_color');
		}
	}

	::v-deep .el-checkbox-group {
		font-size: 14px;
	}

	::v-deep .el-checkbox__label {
		font-size: 0px;
	}
</style>
